import { Show, createSignal } from "solid-js";
import { useNavigate } from "@solidjs/router";
import { CardMedia, Stack, Typography, Avatar, Popover, Grid, ListItem, ListItemText, ListItemButton, ListItemAvatar, Divider } from "@suid/material";
 
import { StarRating } from '../SmallComponents/SmallShowStars';
import { CalendarIcon, OnsiteIcon,  LocationIcon } from '../icons'; 
import { blueGrey, blue, green, grey } from "@suid/material/colors";
import { getText } from '../SmallComponents/Translate'

interface OrganizationCardProps {
    organizationName: string;
    logoUrl?: string;
    jobName: string;
    jobRole?: string;
    jobStartDate: string;
    jobEndDate: string;
    anonymized?: boolean;
    onSiteText?: string;
    matchPct?: number
    locationCountry?: string;
    locationCity?: string;
    wfStatus?: string;
    layout?: string;
}
const AdditonalOrganizationInfo = (jobStart: string, jobEnd: string, onSiteText: string, wfStatus: string, matchPct: number, locationCountry?: string, locationCity?: string) => {
    const txtMatch = getText("smallcomponent", "match")
    const txtStatus = getText("smallcomponent", "status")
    const txtTo = getText("default", "towithspaces")
    return (
        <>
            <Show when={matchPct} >
                <Stack
                    direction="row">
                    <Typography variant="body1" color="success.dark">{matchPct}%</Typography>
                    <Typography variant="body1" sx={{ px: 1 }} color="text.secondary"> {txtMatch()} </Typography>
                </Stack>
            </Show>
            <Show when={wfStatus} >
                <Stack
                    direction="row">
                    <Typography variant="body1" color="text.secondary"> {txtStatus()} </Typography>
                    <Typography variant="body1" sx={{ px: 1 }} color="success.dark">{wfStatus}%</Typography>

                </Stack>
            </Show>
            <Show when={jobStart} >
                <Stack
                    direction="row">
                    <CalendarIcon fontSize="small" color="disabled" />
                    <Typography variant="body2" sx={{ px: 1 }} color="disabled">{jobStart} </Typography>
                    <Show when={jobEnd} >
                        <Typography variant="body2" color="disabled">{txtTo()}{jobEnd}</Typography>
                    </Show>
                </Stack>
            </Show>

            <Show when={onSiteText} >
                <Stack
                    direction="row">
                    <OnsiteIcon fontSize="small" color="disabled" />
                    <Typography variant="body2" sx={{ px: 1 }} color="disabled">{onSiteText}</Typography>
                </Stack>
            </Show>
            <Show when={locationCity} >
                <Stack
                    direction="row">
                    <LocationIcon fontSize="small" color="disabled" />
                    <Typography variant="body2" sx={{ px: 1 }} color="disable">{locationCountry} {locationCity}</Typography>
                </Stack>
            </Show>
        </>
    )
}

const OrganizationCard = ({
    organizationName,
    logoUrl = "",
    jobRole = "",
    jobName,
    jobStartDate, jobEndDate,
    anonymized = false,
    wfStatus = "",
    onSiteText = "",
    matchPct = 0,
    locationCountry = "",
    locationCity = "",
    layout = "column",
}: OrganizationCardProps) =>{
    const openPopover = () => Boolean(anchorEl());
    const [anchorEl, setAnchorEl] = createSignal<Element | null>(null);

    const handlePopoverOpen = (event: { currentTarget: Element }) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const jobStart = jobStartDate //jobStartDate.toDateString();
    const jobEnd = jobEndDate //jobEndDate.toDateString();
    let popoverText = ""
    const txtNoLogo = getText("smallcomponent", "companydontshowlogo")
    const txtNoLogoUploaded = getText("smallcomponent", "companylogonotuploaded")
    

    if (anonymized) {
        logoUrl = "";
        organizationName = "Industry Inc"
        popoverText = txtNoLogo() as string;
    } else if (!logoUrl) {
        popoverText = txtNoLogoUploaded() as string;
    }
    return (
        <>
            {/*  <Card sx={{ display: 'flex' }} > */}
            <ListItem disablePadding alignItems="flex-start" >
                <ListItemButton  >
                    <ListItemAvatar>

                        {/* Show company logo or a letter based Avatar */}
                        <Show
                            when={anonymized}
                            fallback={

                                <Show
                                    when={logoUrl}
                                    fallback={
                                        <Avatar
                                            sx={{
                                                width: 30, height: 30, px: 1, py: 1, margin: 1,
                                                bgcolor: blue[100],
                                                color: blue[600],

                                            }}
                                            variant="rounded"
                                            onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}
                                        >
                                            No logo
                                        </Avatar>
                                    }
                                >
                                    <CardMedia
                                        component="img"
                                        sx={{ width: 45, height: 45, px: 1, py: 1 }}
                                        image={logoUrl}
                                        alt="Anonymized"

                                    />
                                </Show>

                            }
                        >
                            <Avatar
                                sx={{
                                    width: 30, height: 30, px: 1, py: 1, margin: 1,
                                    bgcolor: blue[100],
                                    color: blue[600],
                                }}
                                variant="rounded"
                                onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}
                            >
                                A
                            </Avatar>
                        </Show>
                    </ListItemAvatar>


                    {/* Show job information */}
                    <ListItemText>
                        <Show
                            when={layout == "grid"}
                            fallback={
                                <>
                                    <Stack direction="column" alignItems="left" sx={{ margin: 1, display: 'flex' }}
                                    >

                                        <Typography variant="h6">{organizationName}</Typography>
                                        <Typography variant="body1" color="text.secondary">{jobRole}</Typography>
                                        <Typography variant="body1" color="text.secondary">{jobName}</Typography>

                                        {AdditonalOrganizationInfo(jobStart, jobEnd, onSiteText, wfStatus, matchPct, locationCountry, locationCity)}

                                    </Stack>
                                </>

                            }
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={6} md={6}>
                                    <Stack direction="column" alignItems="left" sx={{ margin: 1, display: 'flex', bgcolor: 'background.default' }}
                                    >
                                        <Typography variant="h6">{organizationName}</Typography>
                                        <Typography variant="body1" color="text.secondary">{jobRole}</Typography>
                                        <Typography variant="body1" color="text.secondary">{jobName}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={6} md={6}>

                                    <Stack
                                        direction="column" alignItems="right" justifyContent="space-between" sx={{ margin: 2, display: 'flex', bgcolor: 'background.default' }}
                                    >
                                        {AdditonalOrganizationInfo(jobStart, jobEnd, onSiteText, wfStatus, matchPct, locationCountry, locationCity)}
                                    </Stack>
                                </Grid>
                            </Grid>

                        </Show>
                    </ListItemText>

                    {/* </Card> */}
                </ListItemButton>

            </ListItem>
           
            <Popover
                id="mouse-over-popover" sx={{ pointerEvents: "none" }} open={openPopover()}
                anchorEl={anchorEl()}
                anchorOrigin={{ vertical: "bottom", horizontal: "left", }}
                transformOrigin={{ vertical: "top", horizontal: "left", }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography sx={{ px: 2, py: 2, }} variant="body2" align="center" gutterBottom> {popoverText}</Typography>
            </Popover>
        </>
    );
}

const OrganizationCardSmall = ({
    organizationName,
    logoUrl = "",
    jobRole = "",
    jobName,
    jobStartDate = "",
    jobEndDate = "",
    onSiteText = "",
    wfStatus = "",
    anonymized = false,

    locationCountry = "",
    locationCity = "",
    layout = "column",
}: OrganizationCardProps) => {
    const openPopover = () => Boolean(anchorEl());
    const [anchorEl, setAnchorEl] = createSignal<Element | null>(null);

    const handlePopoverOpen = (event: { currentTarget: Element }) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    let popoverText = ""

    const txtNoLogo = getText("smallcomponent", "companydontshowlogo")
    const txtNoLogoUploaded = getText("smallcomponent", "companylogonotuploaded")
    
    if (anonymized) {
        logoUrl = "";
        organizationName = "Industry Inc"
        popoverText = txtNoLogo() as string;
    } else if (!logoUrl) {
        popoverText = txtNoLogoUploaded() as string;
    }
    return (
        <>
            {/*  <Card sx={{ display: 'flex' }} > */}
            <ListItem disablePadding  >
                <ListItemButton  >
                    <ListItemAvatar>

                        {/* Show company logo or a letter based Avatar */}
                        <Show
                            when={anonymized}
                            fallback={

                                <Show
                                    when={logoUrl}
                                    fallback={
                                        <Avatar
                                            sx={{
                                                width: 30, height: 30, px: 1, py: 1, margin: 1,
                                                bgcolor: blue[100],
                                                color: blue[600],

                                            }}
                                            variant="rounded"
                                            onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}
                                        >
                                            No logo
                                        </Avatar>
                                    }
                                >
                                    <CardMedia
                                        component="img"
                                        sx={{ width: 45, height: 45, px: 1, py: 1 }}
                                        image={logoUrl}
                                        alt="Anonymized"

                                    />
                                </Show>

                            }
                        >
                            <Avatar
                                sx={{
                                    width: 30, height: 30, px: 1, py: 1, margin: 1,
                                    bgcolor: blue[100],
                                    color: blue[600],
                                }}
                                variant="rounded"
                                onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}
                            >
                                A
                            </Avatar>
                        </Show>
                    </ListItemAvatar>


                    {/* Show job information */}
                    <ListItemText>
                        <Show
                            when={layout === "grid"}
                            fallback={
                                <>
                                    <Stack direction="column" alignItems="left" sx={{ margin: 1, display: 'flex' }}
                                    >

                                        <Typography variant="h6">{organizationName}</Typography>
                                        <Typography variant="body1" color="text.secondary">{jobRole}</Typography>
                                        <Typography variant="body1" color="text.secondary">{jobName}</Typography>

                                        {AdditonalOrganizationInfo(jobStartDate, jobEndDate, onSiteText, wfStatus, 0, locationCountry, locationCity)}

                                    </Stack>
                                </>

                            }
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={6} md={6}>
                                    <Stack direction="column" alignItems="left" sx={{ margin: 1, display: 'flex', bgcolor: 'background.default' }}
                                    >
                                        <Typography variant="h6">{organizationName}</Typography>
                                        <Typography variant="body1" color="text.secondary">{jobRole}</Typography>
                                        <Typography variant="body1" color="text.secondary">{jobName}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={6} md={6}>

                                    <Stack
                                        direction="column" alignItems="right" justifyContent="space-between" sx={{ margin: 2, display: 'flex', bgcolor: 'background.default' }}
                                    >


                                        {AdditonalOrganizationInfo(jobStartDate, jobEndDate, onSiteText, wfStatus, 0, locationCountry, locationCity)}
                                    </Stack>
                                </Grid>
                            </Grid>

                        </Show>
                    </ListItemText>

                    {/* </Card> */}
                </ListItemButton>

            </ListItem>
            <Divider variant="inset" component="li" />
            <Popover
                id="mouse-over-popover" sx={{ pointerEvents: "none" }} open={openPopover()}
                anchorEl={anchorEl()}
                anchorOrigin={{ vertical: "bottom", horizontal: "left", }}
                transformOrigin={{ vertical: "top", horizontal: "left", }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography sx={{ px: 2, py: 2, }} variant="body2" align="center" gutterBottom> {popoverText}</Typography>
            </Popover>
        </>
    );
}


interface SmallListComponentItemProps {
    clickURL: string;
    title: string;
    subTitle1?: string;
    subTitle2?: string;
    subTitle3?: string;
    description?: string;
    highlightText?: string;
    rating?: string;
    src?: string;
    withAvatar?: boolean;
}

const SmallListComponentItem = ({
    clickURL = "",
    title = "",
    subTitle1 = "",
    subTitle2 = "",
    subTitle3 = "",
    description = "",
    highlightText ="",
    rating = "",
    src = "",
    withAvatar = true
}: SmallListComponentItemProps) => {
    const openPopover = () => Boolean(anchorEl());
    const [anchorEl, setAnchorEl] = createSignal<Element | null>(null);

    const handlePopoverOpen = (event: { currentTarget: Element }) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const navigate = useNavigate();
    let popoverText = ""

    return (
        <>
            {/*  <Card sx={{ display: 'flex' }} > */}
            <ListItem disablePadding  >
                <ListItemButton  onClick={ () => navigate(clickURL)}>
                    <Show
                        when={withAvatar}
                    >
                        <ListItemAvatar >
                            {/* Show company logo or a letter based Avatar */}
                            <Show
                        when={src}
                    >
                            <Avatar
                                variant="rounded"
                                src={src}
                                
                            />
                             </Show>
                        </ListItemAvatar>
                    </Show>

                    {/* Show job information */}
                    <ListItemText>
                        <Show
                            when={title}
                        >
                            <Typography variant="body2" fontSize="90%" >{title}</Typography>
                        </Show>
                        <Show
                            when={subTitle1}
                        >
                            <Typography variant="body2" fontSize="90%" color="text.secondary">{subTitle1}</Typography>
                        </Show>
                        <Show
                            when={subTitle2}
                        >
                            <Typography variant="body2" fontSize="80%" color="text.secondary">{subTitle2}</Typography>
                        </Show>
                        <Show
                            when={subTitle3}
                        >
                            <Typography variant="body2" fontSize="80%" color="text.secondary">{subTitle3}</Typography>
                        </Show>
                        <Show
                            when={rating}
                        >
                             <StarRating rating={Number(rating)} />
                          </Show>
                        <Show
                            when={highlightText}
                        >
                            <Typography variant="body2" fontSize="80%" color="success.dark">{highlightText}</Typography>
                        </Show>
                        <Show
                            when={description}
                        >
                            <Typography variant="body2" fontSize="80%" color="text.secondary">{description}</Typography>
                        </Show>
                      
                    </ListItemText>

                    {/* </Card> */}
                </ListItemButton>

            </ListItem>
            {/*   <Divider variant="inset" component="li" />  */}

        </>
    );
}

export { OrganizationCard, SmallListComponentItem, OrganizationCardSmall }